import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import useStores from '../hooks/use-stores';
import { observer } from 'mobx-react';
import loginBg from '../images/login.jpg';
import logo from '../images/logoTrans.png';
import { CircularProgress } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../auth-config';
import { callMsGraph, getEmployeeIdBycallMsGraph } from "../../graph";
import { signInMethod } from '../constants/options';
import { useTranslation } from 'react-i18next';
import { isProduction } from '../constants/settings';

const IndexPage = observer(() => {
  const { rootStore, userStore } = useStores();
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    // if (typeof window !== "undefined" && window.location.href.indexOf('?token=') > 0) {
    //   navigate("reset-password", {
    //     state: { token: window.location.href.split('?token=')[1] }
    //   })
    // } else {
    if (userStore.isAuthed) {
      if (typeof window !== "undefined" && localStorage.USER_INFO) {
        rootStore.notify(`${t('WELCOME')} ` + JSON.parse(localStorage.USER_INFO).firstName + "!", 'success');
        navigate("/dashboard");
      }
    }
    else if (typeof window !== "undefined" && localStorage.SIGN_IN_METHOD == signInMethod.sso) {
      sessionStorage.clear();
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        let token = response.accessToken;
        callMsGraph(response.accessToken).then((userInfo) => {
          // userStore.getTokenByMsalId(userInfo.mail).then(() => setStatus(true));
          getEmployeeIdBycallMsGraph(token, userInfo.id).then((detailInfo) => {
            let emailList = [];
            if (detailInfo.imAddresses.length > 0) emailList = emailList.concat(detailInfo.imAddresses);
            if (detailInfo.otherMails.length > 0) emailList = emailList.concat(detailInfo.otherMails);
            if (detailInfo.mail != "") emailList.push(detailInfo.mail);
            let uniqueList = emailList.filter((v, i, a) => a.indexOf(v) === i);
            userStore.getTokenByMsalId(uniqueList).then(() => setStatus(true))
              .catch(() => {
                localStorage.clear();
                location.reload();
              });
          });
        });
      })
    } else {
      localStorage.clear();
      location.reload();
    }

    // }
  }, [status]);

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 theme-primary bg-barPrimary z-10">
      <title>{t('SIGN_IN')}</title>
      <div
        className="relative top-1/2 left-1/2 bg-white rounded-xl p-10"
        style={{ transform: "translate(-50%, -50%)", width: 'fit-content' }}>
        <div className="text-center">
          <CircularProgress className="text-barPrimary" />
          <div className="text-barPrimary mt-4 text-sm">{t('VALIDATING_USER_CREDENTIAL')}...</div>
        </div>
      </div>
    </div>
  )
})

export default IndexPage;

