import { graphConfig } from "./auth-config";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

/**
 * Attaches a given access token and user id to a MS Graph API call. Returns information about the user (employee details)
 * @param accessToken 
 */
 export async function getEmployeeIdBycallMsGraph(accessToken, id) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphEmployeeIdEndpoint + `${id}`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}